$(document).ready(function () {
    $('.testimonials .slider').slick({
        dots: true,
        arrows: false,
        autoplay: true
    });
    $(window).on('scroll', function () {
        var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        if (scrollTop > 500) {
            $("#up-button").addClass("active");
        } else {
            $("#up-button").removeClass("active");
        }
        if ($(window).width() > 991) {
            if (scrollTop > 150) {
                $(".header-wrapper .header .navigation").addClass('fixed');
                $(".intro").css('margin-top', '190px');
            } else {
                $(".header-wrapper .header .navigation").removeClass('fixed');
                $(".intro").css('margin-top', '0');
            }
        }
    });
    $("#up-button").click(function (e) {
        $(document.documentElement || document.body.parentNode || document.body).stop().animate({ scrollTop: 0 }, 500, 'swing');
    });
    $(".navigation .menu.home-menu ul li a").on('click', function (e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top)
        }, 1000);
        var mobileMenu = $("#mobile-menu-toggle");
        if (mobileMenu && $(window).width() < 993) {
            $(mobileMenu).toggleClass("active");
            $(".navigation .menu").first().slideToggle(300);
        }
    });
    $("#mobile-menu-toggle").on('click', function (e) {
        $(this).toggleClass("active");
        $(".navigation .menu").first().slideToggle(300);
    });
    $("#shop-owner-button").on('click', function (e) {
        const shopForm = $('#shop-form');
        const shopAlert = $('#shop-alert')
        shopAlert.html('');
        shopForm.addClass('loading');
        const payload = {
            shopRequest: {
                ownerName: $('#ownerName').val(),
                name: $('#name').val(),
                address: $('#address').val(),
                email: $('#email').val(),
                phone: $('#phone').val(),
                description: $('#description').val(),
                userId: 'whappo-website'
            }
        };
        const formInvalid = Object.keys(payload.shopRequest).some(key => !payload.shopRequest[key] || payload.shopRequest[key].length > 200);
        console.log(formInvalid);
        if (formInvalid) {
            shopAlert.html(`
                <div class="alert alert-danger"
                        role="alert">
                    Lütfen tüm alanları doldurun (her alan için maksimum 200 karakter)
                </div>
            `);
            shopForm.removeClass('loading');
        } else {
            $.ajax({
                type: "POST",
                url: 'http://api.whappo.com/api/shop/addNewShopRequest?allowToken=true',
                data: JSON.stringify(payload),
                contentType: "application/json;charset=utf-8",
                dataType: 'json'
            }).done(function (res) {
                shopAlert.html(`
                    <div class="alert alert-success"
                            role="alert">
                        Başvurunuz bize ulaştı, en kısa sürede sizinle iletişime geçeceğiz
                    </div>
                `);
                $('#ownerName').val('');
                $('#name').val('');
                $('#address').val('');
                $('#email').val('');
                $('#phone').val('');
                $('#description').val('');
                shopForm.removeClass('loading');
            }).fail(function (err) {
                shopAlert.html(`
                    <div class="alert alert-danger"
                            role="alert">
                        Beklenmeyen bir hata oluştu, lütfen daha sonra tekrar deneyin
                    </div>
                `);
                shopForm.removeClass('loading');
            });
        }
    });
});

